import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorBoundary() {
  const error = useRouteError();
  return (
    <div className="w-screen absolute left-0 bg-white bg-opacity-75 p-4 sm:p-12 leading-loose text-black lg:text-2xl space-y-8 mt-16">
      <div className="mx-auto max-w-prose space-y-8">
        <h1 className="text-4xl flex items-center text-orange-500 font-bold">
          <div>Oops!</div>
        </h1>
        <h2>
          We are really sorry something went wrong. Please try again and let us
          know if the problem persists.
        </h2>
        <h2>
          {isRouteErrorResponse(error) ? (
            <>
              <span className="font-bold">{error.status}</span>
              <span className="p-2">/</span>
              <span>{error.statusText}</span>
            </>
          ) : (
            <span className="font-bold">Something unexpected happened</span>
          )}
        </h2>
        {isRouteErrorResponse(error) ? (
          error.data?.message && <span>{error.data.message}</span>
        ) : (
          <p className="font-mono whitespace-pre-wrap">
            {JSON.stringify(error, null, 2)}
          </p>
        )}
      </div>
    </div>
  );
}
