import React from "react";
import { useField } from "formik";
import Error from "./Error.js";

export default function Checkbox({ children, help_text, ...props }) {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <div className="relative flex gap-x-3">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
            id={props.name}
            {...field}
            {...props}
          />
        </div>
        <div className="text-sm leading-6">
          <label htmlFor={props.id || props.name} className="text-black">
            {children}
          </label>
        </div>
      </div>
      <div>{meta.touched && meta.error && <Error meta={meta} />}</div>
    </>
  );
}
