import { NavLink } from "react-router-dom";
import Button from "../components/Button";

export default function LandingPage() {
  return (
    <div className="w-screen absolute left-0 bg-white bg-opacity-75 p-4 sm:p-12 leading-loose text-black lg:text-2xl space-y-8 mt-16">
      <div className="mx-auto max-w-prose space-y-8 text-center">
        <p>
          Very sadly, our beloved village pub, the Fox and Hounds, closed its
          doors on 30th July 2024.
        </p>
        <p>
          The Charltons community don't yet know what will happen next, but many
          of us are motivated to make sure the pub re-opens in some form as soon
          as possible, because it is a treasured community hub.
        </p>
        <p>
          At this stage, we are collecting contact details so we can keep
          everyone up-to-date as the situation develops, as well as ideas for
          protecting the future of "The Fox".
        </p>
        <p>We will keep this site up-to-date with news, as it comes in.</p>
        <p>
          Please click the button below to get involved. We will need your help!
        </p>
        <div className="m-4">
          <NavLink to="/help">
            <Button>I want to help Save The Fox!</Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
