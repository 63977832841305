import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Help from "./pages/Help";
import LandingPage from "./pages/LandingPage";
import News from "./pages/News";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

const Layout = () => {
  return (
    <div className="h-svh flex min-h-full flex-col font-light">
      <Header />
      <div className="grow bg-white h-full sm:bg-landing sm:bg-cover">
        <div className="mx-auto">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const ErrorLayout = () => (
  <div className="h-svh flex min-h-full flex-col font-light">
    <Header />
    <div className="grow bg-white h-full sm:bg-landing sm:bg-cover">
      <div className="mx-auto">
        <ErrorBoundary />
      </div>
    </div>
    <Footer />
  </div>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorLayout />,
    path: "/",
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "news",
        element: <News />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
