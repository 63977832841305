import React from "react";
import clsx from "clsx";
import { useField } from "formik";
import Error from "./Error.js";

export default function Select({ label, help_text, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className="relative">
      <label
        htmlFor={props.id || props.name}
        className={clsx(
          "absolute -top-2 left-2 inline-block bg-orange-500 px-1 text-xs font-bold rounded",
          meta.touched && meta.error ? "text-red-600" : "text-white",
        )}
      >
        {label}
      </label>
      <div>
        <select
          className={clsx(
            "text-input block py-1.5 rounded-md border-0 text-black shadow-sm ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6",
            meta.touched && meta.error
              ? "ring-2 ring-red-300"
              : "ring-1 ring-orange-500",
          )}
          id={props.name}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && <Error meta={meta} />}
      </div>
      <p className="text-sm leading-6 text-gray-600">{help_text}</p>
    </div>
  );
}
