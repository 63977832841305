import React from "react";
import clsx from "clsx";

const themeStyles = {
  default:
    "bg-orange-500 hover:bg-orange-400 text-white rounded whitespace-nowraptext-3xl font-bold p-6",
  nav: "bg-orange-500 hover:bg-orange-400 text-white rounded whitespace-nowraptext-3xl font-bold p-2",
  disabled:
    "bg-white text-black ring-black rounded whitespace-nowraptext-3xl font-bold p-6",
};

const Button = React.forwardRef((props, ref) => {
  const themeClasses = themeStyles[props.theme] || themeStyles["default"];

  return (
    <button
      type="button"
      className={clsx(props.className, themeClasses)}
      ref={ref}
      {...props}
    >
      {props.children}
    </button>
  );
});

export default Button;
