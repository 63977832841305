import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";

export default function Header() {
  return (
    <div className="w-full bg-black flex space-x-8">
      <header className="px-4 sm:px-6 lg:px-8 py-4 flex items-center space-x-4">
        <NavLink to="/" end className="items">
          <img
            src="/images/logo-white.svg"
            className="h-16"
            alt="Stylised logo of a fox's face"
          />
        </NavLink>
        <h1 className="text-white font-bold text-4xl whitespace-nowrap">
          Save The Fox
        </h1>
      </header>
      <div className="flex items-center space-x-8 text-2xl">
        <NavLink to="/news">
          <Button theme="nav">News</Button>
        </NavLink>
        <NavLink to="/help">
          <Button theme="nav">Help!</Button>
        </NavLink>
      </div>
    </div>
  );
}
