import React from "react";

export default function Error({ meta, ...props }) {
  return (
    <div
      className="flex items-center error text-sm text-red-600 font-semibold mt-1"
      {...props}
    >
      {meta.error}
    </div>
  );
}
