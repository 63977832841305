import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4 md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 text-center text-xs p-2">
          <p className="text-center text-xs text-white">
            &copy; 2024 Bradley Consultancy. All rights reserved.
          </p>
        </div>
        <div className="flex justify-center space-x-6 text-center text-xs p-2">
          <NavLink to="/privacy" end className="text-white hover:underline">
            Privacy Policy
          </NavLink>
          <NavLink to="/terms" end className="text-white hover:underline">
            Terms and Conditions
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
