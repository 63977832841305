import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../components/Button";
import Checkbox from "../components/form/Checkbox";
import TextInput from "../components/form/TextInput";
import Select from "../components/form/Select";
import useContactService from "../hooks/ContactService";

export default function Help() {
  const { createContact } = useContactService();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");

  const schema = Yup.object().shape({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter an email address."),
    location: Yup.string().required("Please select a location."),
    consent: Yup.bool().oneOf([true], "Please give us your consent."),
  });

  if (success) {
    return (
      <div className="w-screen absolute left-0 bg-white bg-opacity-75 p-4 sm:p-12 leading-loose text-black lg:text-2xl space-y-8 mt-16">
        <div className="mx-auto max-w-prose space-y-8 text-center">
          <p>Thank you for signing up, {name}!</p>
          <p>
            We will use the contact details you have provided to keep you
            informed about developments regarding our beloved local pub, the Fox
            and Hounds.
          </p>
          <p>
            If you'd like to familiarise yourself with what we know so far, head
            over to the{" "}
            <NavLink to="/news" className="underline">
              News
            </NavLink>{" "}
            page for more information.
          </p>
          <p>Thank you for your support!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen absolute left-0 bg-white bg-opacity-75 p-4 sm:p-12 leading-loose text-black lg:text-2xl space-y-8 mt-16">
      <div className="mx-auto max-w-prose space-y-8">
        <Formik
          initialValues={{
            name: "",
            email: "",
            location: "",
            consent: false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            setSubmitting(true);
            createContact(null, values)
              .then(function (res) {
                setName(values.name);
                setSuccess(true);
              })
              .catch(function (res) {
                throw res;
              });
            setSubmitting(false);
          }}
        >
          {({ dirty, isValid }) => (
            <Form>
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                  <div>
                    <h2 className="text-base font-semibold leading-relaxed text-black">
                      Who are you?
                    </h2>
                    <p className="mt-1 text-sm leading-relaxed text-gray-600">
                      We will use this information to send you periodic updates.
                    </p>
                  </div>
                  <div className="grid md:col-span-2 mt-6">
                    <div className="mt-2 space-y-8">
                      <TextInput
                        name="name"
                        label="name"
                        placeholder="Joe Bloggs"
                      />
                      <TextInput
                        name="email"
                        label="e-mail address"
                        placeholder="joe@bloggs.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                  <div>
                    <h2 className="text-base font-semibold leading-relaxed text-black">
                      Where are you?
                    </h2>
                    <p className="mt-1 text-sm leading-relaxed text-gray-600">
                      Knowing the area where you live will help us understand
                      the reach of the pub and the size of its market.
                    </p>
                  </div>
                  <div className="grid md:col-span-2 mt-6">
                    <div className="mt-2 space-y-8">
                      <Select name="location" label="location">
                        <option value="" className="text-gray-400">
                          Select the closest match for where you live
                        </option>
                        <option value="CF">Cary Fitzpaine</option>
                        <option value="CA">Charlton Adam</option>
                        <option value="CM">Charlton Mackrell</option>
                        <option value="KM">Keinton Mandeville</option>
                        <option value="L">Lydford</option>
                        <option value="LC">Lytes Cary</option>
                        <option value="W">Welham</option>
                        <option value="WC">West Charlton</option>
                        <option value="FA">-further afield-</option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                  <div>
                    <h2 className="text-base font-semibold leading-relaxed text-black">
                      The legal bit
                    </h2>
                    <p className="mt-1 text-sm leading-relaxed text-gray-600">
                      We need your consent to collect and use your personal
                      information.
                    </p>
                  </div>
                  <div className="grid md:col-span-2">
                    <div className="mt-2">
                      <Checkbox name="consent">
                        I have read the{" "}
                        <NavLink to="/privacy" className="underline">
                          Privacy Policy
                        </NavLink>{" "}
                        and{" "}
                        <NavLink to="/terms" className="underline">
                          Terms and Conditions
                        </NavLink>{" "}
                        and give my consent for my personal information to be
                        collected and stored.
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    theme={dirty && isValid ? "default" : "disabled"}
                    type="submit"
                    disabled={!(dirty && isValid) || submitting}
                  >
                    {submitting && (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          class="inline w-8 h-8 me-3 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#f97316"
                          />
                        </svg>
                        Saving...
                      </>
                    )}{" "}
                    {!submitting && <>Sign me up!</>}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
