export default function News() {
  return (
    <div className="w-screen absolute left-0 bg-white bg-opacity-75 p-4 sm:p-12 leading-loose text-black lg:text-2xl space-y-8 mt-16">
      <div className="mx-auto max-w-prose space-y-8">
        <ol className="relative border-s border-black">
          <li className="mb-6 ms-4">
            <div className="absolute w-3 h-3 bg-black rounded-full mt-3 -start-1.5 border border-white"></div>
            <time className="mb-1 text-sm font-normal leading-none">
              29th June 2024
            </time>
            <h3 className="text-lg font-semibold">Closure Announced</h3>
            <p className="mb-4 text-base font-normal">
              The Fox and Hounds{" "}
              <a
                href="https://www.facebook.com/share/p/2CD3HEB8jkzwmqhc/"
                className="underline"
              >
                announces
              </a>{" "}
              that it will be closing on 31st June 2024:
            </p>
            <div className="space-y-4 ml-4 mt-4">
              <p className="text-base font-normal italic">
                Hi everybody! It is with sadness that we are informing you the
                pub will be shut as of next Monday due to insolvency.
              </p>
            </div>
          </li>
          <li className="mb-6 ms-4">
            <div className="absolute w-3 h-3 bg-black rounded-full mt-3 -start-1.5 border border-white"></div>
            <time className="mb-1 text-sm font-normal leading-none">
              31st June 2024
            </time>
            <h3 className="text-lg font-semibold">Last Pint</h3>
            <p className="text-base font-normal">
              The last pint under the tenure of the outgoing landlords, Gabriel
              and Irina, is poured.
            </p>
          </li>
          <li className="ms-4">
            <div className="absolute w-3 h-3 bg-black rounded-full mt-3 -start-1.5 border border-white"></div>
            <time className="mb-1 text-sm font-normal leading-none">
              1st July 2024
            </time>
            <h3 className="text-lg font-semibold">Owner Replies</h3>
            <p className="text-base font-normal">
              The owner of the pub,{" "}
              <a href="https://www.grovepropery.com" className="underline">
                Grove Property
              </a>
              , replies:
            </p>
            <div className="space-y-4 ml-4 mt-4">
              <p className="text-base font-normal italic">
                We are also very sad to hear the news that the pub has closed
                its doors, as we have been working hard with Gabriel and Irina
                in supporting them to keep the pub open.
              </p>
              <p className="text-base font-normal italic">
                Unfortunately, it is unlikely that we will find another landlord
                to run the pub over the summer, however we will bring forward
                our commitment to invest in the pub to ensure that it can remain
                at the heart of the community as a much loved rural pub.
              </p>
              <p className="text-base font-normal italic">
                Please get in touch if you know of anyone that would be
                interested in managing the pub once renovated.
              </p>
              <p className="text-base font-normal italic">
                We would like to extend our best wishes to Gabriel and Irena for
                the future.
              </p>
              <p className="text-base font-normal italic">
                Kind regards, The Grove Property Team.
              </p>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
}
